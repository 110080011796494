<template>
  <multi-step-dialog title="Select a Workout Collection" :step="step" ref="dialog" :can-confirm="!!collectionId" @input="confirm">
    <v-stepper-items>
      <v-stepper-content :step="1" class="pa-0">
        <div v-if="!collections" class="text-center">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </div>
        <v-container v-if="collections">
          <p>Select a collection for details and to apply.</p>
          <v-row>
            <v-col v-for="(item, idx) in collections" :key="idx" cols="6" md="4" lg="3">
              <workout-collection-card :item="item" @click="nextStep" />
            </v-col>
          </v-row>
        </v-container>

      </v-stepper-content>
      <v-stepper-content :step="2" class="pa-0">
        <v-container v-if="collection">
          <v-row>
            <v-col>
              <h4>{{ collection.title }}</h4>
              <p>{{ collection.description }}</p>


              <h4>Videos</h4>
              <div v-if="!videos" class="text-center">
                <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
              </div>
              <v-container v-if="videos">
                <p>These videos are included in the selected collection.</p>
                <v-row>
                  <v-col v-for="(item, idx) in videos" :key="idx" cols="12">
                    <workout-video-card :item="item" />
                  </v-col>
                </v-row>
              </v-container>

            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </multi-step-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import assetsService from "@/services/assetsService";
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";
import WorkoutCollectionCard from '../workouts/WorkoutCollectionCard.vue';
import WorkoutVideoCard from '../workouts/WorkoutVideoCard.vue';

export default {
  name: "SelectWorkoutCollectionDialog",
  components: {
    MultiStepDialog,
    WorkoutCollectionCard,
    WorkoutVideoCard,
  },
  props: {
    value: String,
  },
  data() {
    return {
      siteData: siteData,
      step: 1,
      collections: null,
      collectionId: null,
      videos: null,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      this.step = 1;
      this.$refs.dialog.open();
      this.collections = this.collections || (await assetsService.getWorkoutCollections()).data.data;
    },

    async nextStep(value) {
      this.collectionId = value;
      this.step = 2;
      var response = (await assetsService.getWorkoutCollection(this.collectionId)).data;
      this.videos = response.videos;
    },

    async confirm() {
      this.$emit('input', this.collectionId);
    }

  },

  computed: {
    collection() {
      if (!this.collectionId) {
        return null;
      }
      return this.collections.find(x => x.id == this.collectionId);
    }
  },

};
</script>
<style lang="scss">
.v-card.active { outline: solid 5px var(--v-accent-base);}
</style>

