<template>
  <div class="player-container">
    <div v-show="!isPlaying" class="poster-overlay">
      <slot name="posterOverlay">
      </slot>
    </div>

    <link href="//vjs.zencdn.net/8.21.1/video-js.min.css" rel="stylesheet" />
    <video ref="videoPlayer" class="video-js"></video>

  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "VideoPlayer",
  props: {
    posterUrl: String,
    streamUrl: String,
    autoLoad: Boolean,
    locked: Boolean,
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: function() {
    return {
      player: null,
      isPlaying: false,
    };
  },
  created() {
  },
  mounted() {
    if (this.autoLoad) {
      this.createPlayer()
    }
  },
  beforeDestroy() {
    this.destroyPlayer()
  },
  methods: {
    async loadVideo() {
      await this.createPlayer(true);
    },
    async createPlayer(autoPlay) {
      console.log('[player] preparing player');
      var component = this;
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
        this.player.log('onPlayerReady', this, this.videoOptions);
        if (autoPlay) this.player.play();
      });
      this.player.on("play", function(){
        console.log('[player] event play');
        component.isPlaying = true;
      });
      this.player.on("pause", function(){
        console.log('[player] event pause');
        component.isPlaying = false;
      });
      this.player.on("ended", function(){
        console.log('[player] event ended');
        component.isPlaying = false;
      });
    },
    async destroyPlayer() {
      if (this.player) {
        await this.player.dispose();
      }
    },
  },
  watch: {
    async streamUrl() {
      console.log('/// streamurl changing, recreate player');
      //await this.destroyPlayer();
      //await this.createPlayer();
      if (this.player) {
        this.player.src({type: 'application/x-mpegURL', src: this.streamUrl});
      }
    },
    async posterUrl() {
      if (this.player) {
      console.log('/// posterUrl changing, updating player');
        await this.player.poster(this.posterUrl);
      }
    },
  },
  computed: {
    videoOptions() {
      // Docs: https://videojs.com/guides/options/
      return { 
        poster: this.posterUrl,
        autoplay: false,
        controls: true,
        aspectRatio: '16:9',
        sources: [
          {
            src: this.streamUrl,
            type: 'application/x-mpegURL'
          }
        ],
        ...this.options,
      }
    }
  }
};
</script>


<style scoped lang="scss">
  .player-container {
    position:relative;

    .poster-overlay {
      position:absolute;
      top:0px;
      left:0px;
      right: 0px;
      z-index: 3;
    }
  }
</style>