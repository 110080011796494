<template>
  <div v-if="event && race">
    <v-card-title class="headline">Workouts</v-card-title>

    <v-container v-if="videosAvailable">
      <v-row>
        <v-col v-for="(item, idx) in videosAvailable" :key="idx" cols="12">
          <workout-video-card :item="item" />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="videosUnavailable">
      <v-row>
        <v-col v-for="(item, idx) in videosUnavailable" :key="idx" cols="12" md="6">
          <workout-video-card :item="item" locked />
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import tenants from '@/data/tenants.config'
import WorkoutVideoCard from './workouts/WorkoutVideoCard.vue';
const tenant = tenants.current();

export default {
  name: "RaceResultsVideos",
  components: {
    WorkoutVideoCard
  },
  props: {
      event: Object,
      race: Object,
      meta: Object,
      badges: Array,
  },
  data() {
    return {
      videosAvailable: null,
      videosUnavailable: null,
    };
  },
 
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (this.race && this.race.scoring === 'BADGES' && this.race.badge_scoring === 'WORKOUT_VIDEOS') {
        var response = (await eventService.getWorkoutVideos(this.event.id, this.race.id)).data;
        //this.tiles = tileBadges.map(x => (x));
        console.log('LOADING videos', response); 
        this.videosAvailable = response.available;
        this.videosUnavailable = response.unavailable;
      }
    },


  },
  watch: {
  },
  computed: {
    
  },

};
</script>
<style lang="scss">


</style>

