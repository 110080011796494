<template>
    <v-card
      :to="hasClickListener ? null : (routeResolver ? routeResolver(data.id) : route)"
      @click="hasClickListener || (! routeResolver && !route) ? $emit('click', data.id) : null"
      class="collection-card"
      >
      <v-img
        v-if="data.thumbnail_url"
        :src="data.thumbnail_url"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-chip small color="white" style="position:absolute; top: 10px; right: 10px;"><v-icon x-small class="me-2">fa-photo-video</v-icon> {{ data.video_count }} videos</v-chip>
        <v-chip v-if="data.activity_type" small color="white" style="position:absolute; top: 10px; left: 10px;"><v-icon x-small class="">{{$helpers.getActivityIcon(data.activity_type)}}</v-icon></v-chip>
        <v-chip v-if="data.published == false" small color="white" style="position:absolute; bottom: 10px; left: 10px;"><v-icon x-small class="me-2">fa-eye-slash</v-icon> Unpublished</v-chip>
        <!-- <v-card-title>{{item.title}}</v-card-title> -->
      </v-img>
      <v-sheet v-else :color="$helpers.getGradientColor(idx)" height="200">
        <v-card-title>{{data.title}}</v-card-title>
      </v-sheet>
    </v-card>
</template>

<script>
import Vue from "vue";

export default {
  name: "WorkoutCollectionCard",
  props: {
    item: Object,
    route: Object,
    minimal: Boolean,
    icon: String,
    index: Number,
    routeResolver: Function,
  },
  emits: {
    //click
  },
  data: function() {
    return {
      data: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click && this.$listeners.click.length;
    },
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        this.data = val;
      }
    }
  }
};
</script>

<style scoped>
  .collection-card { }
</style>