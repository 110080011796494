<template>
  <video-player ref="player" :streamUrl="workout.streaming_url" :posterUrl="workout.thumbnail_url" :autoLoad="autoLoad" :locked="locked">
    <template v-slot:posterOverlay="{ }">
      <div class="d-flex pa-4">
        <v-chip color="white" class="d-flex">
          <v-icon x-small class="me-2">fa-heart-rate</v-icon>
          <span>●</span>
          <span v-if="intensityLevel >= 2">●</span><span v-else>○</span>
          <span v-if="intensityLevel >= 3">●</span><span v-else>○</span>
          <span v-if="intensityLevel >= 4">●</span><span v-else>○</span>
          <span v-if="intensityLevel >= 5">●</span><span v-else>○</span>
        </v-chip>
        <v-spacer />
        <v-chip small color="white" class="d-flex">
          <v-icon x-small class="me-1">fa-stopwatch</v-icon>
          {{ workout.duration_s | duration(true) }}
        </v-chip>
      </div>
    </template>
  </video-player>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: "WorkoutVideoPlayer",
  props: {
    workout: Object,
    autoLoad: Boolean,
    locked: Boolean,
  },
  components: {
    VideoPlayer,
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    loadVideo() {
      this.$refs.player.loadVideo();
    }
  },
  watch: {
    
  },
  computed: {
    intensityLevel() {
      if (!this.workout || !this.workout.intensity) {
        return null;
      }
      if (this.workout.intensity == 'LOW') return 1;
      if (this.workout.intensity == 'LOW_MED') return 2;
      if (this.workout.intensity == 'MED') return 3;
      if (this.workout.intensity == 'MED_HIGH') return 4;
      if (this.workout.intensity == 'HIGH') return 5;

      return null;
    }
  }
};
</script>


<style scoped lang="scss">
  .player-container {
    position:relative;

    .poster-overlay {
      position:absolute;
      top:0px;
      left:0px;
      right: 0px;
      z-index: 3;
    }
  }
</style>