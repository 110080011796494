<template>
  <v-card class="mt-16">
    <v-breadcrumbs 
      :items="[ { text: 'Admin', exact: true, to: { name: 'adminHome' } }, { text: 'Workouts', exact: true, to: { name: 'adminWorkouts' } }, ]"
      class="mx-4"
      />

    <v-card-title class="headline">
      Workout Collection
    </v-card-title>

    <link href="//vjs.zencdn.net/8.21.1/video-js.min.css" rel="stylesheet" />

    <div v-if="collection">
      <v-card-subtitle>{{collection.title}}</v-card-subtitle>
      <v-card-text>{{collection.description}}</v-card-text>
      <v-card-text v-if="collection.tags">
        Tags: <v-chip v-for="tag in collection.tags" :key="tag" small outlined class="me-2">{{tag}}</v-chip>
      </v-card-text>
      <v-card-text>
        <v-chip v-if="collection.published == false" color="accent" small class="me-2">UNPUBLISHED</v-chip>
        <v-chip v-if="collection.featured" color="accent" small class="me-2">Featured</v-chip>
      </v-card-text>
      <v-card-text>
        <v-btn @click="$refs.configDialog.open();">Edit Configuration</v-btn>
      </v-card-text>

    <v-container class="grey lighten-2">
      <v-card-subtitle>Videos ({{collection.video_count}})</v-card-subtitle>

    <v-row v-if="videos">
      <v-col v-for="(item, idx) in videos" :key="idx" cols="6">
        <workout-video-card :item="item" />
        <v-card-text v-if="item.description">{{item.description}}</v-card-text>
        <div v-if="item.tags" class="pa-4">
          Tags: 
          <v-chip v-for="tag in item.tags" :key="tag" outlined small class="me-2">{{tag}}</v-chip>
        </div>
        <div v-if="item.equipment && item.equipment.length" class="pa-4">
          Equipment:
          <v-chip v-for="equipment in item.equipment" :key="equipment" outlined small class="me-2">{{equipment}}</v-chip>
        </div>
      </v-col>
    </v-row>
    </v-container>
    </div>



    <multi-step-dialog v-if="config" ref="configDialog" title="Configuration" confirm-label="Save" single-step @confirm="saveConfig">
      <v-card-text>

        <v-select
          :items="$helpers.prefixSelectListWith(siteData.boolean_list, '- Not set -')"
          v-model="config.published" 
          item-value="value"
          item-text="text"
          label="Published (available)"
          placeholder="- Not set -"
          />

        <v-select
          :items="$helpers.prefixSelectListWith(siteData.boolean_list, '- Not set -')"
          v-model="config.featured" 
          item-value="value"
          item-text="text"
          label="Featured Collection"
          placeholder="- Not set -"
          />

        <v-select
          :items="$helpers.prefixSelectListWith(siteData.activities, '- Not set -')"
          v-model="config.activity_type" 
          item-value="type"
          item-text="text"
          label="Activity Type"
          placeholder="- Not set -"
          />


      </v-card-text>
    </multi-step-dialog>

  </v-card>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import WorkoutVideoPlayer from '../../components/WorkoutVideoPlayer.vue';
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";
import WorkoutVideoCard from '../../components/workouts/WorkoutVideoCard.vue';

export default {
  name: "Workouts",
  components: {
    WorkoutVideoPlayer,
    MultiStepDialog,
    WorkoutVideoCard,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      data: null,
      collection: null,
      videos: null,
      config: null,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {

    async getData() {
      this.data = (await adminService.getWorkoutCollection(this.$route.params.id)).data;
      this.collection = this.data.collection;
      this.videos = this.data.videos;
      this.config = this.data.config || {};
    },

    async saveConfig() {
      var response = (await adminService.putWorkoutCollection(this.$route.params.id, this.config)).data;
      this.$helpers.toastResponse(this, response, 'Config saved successfully');
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

