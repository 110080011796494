<template>
  <v-dialog v-if="event" v-model="visible" max-width="650">
    <v-card>
        <v-card-title>
            <span class="headline">Add a workout video challenge</span>
        </v-card-title>

        <v-card-text>
          <p>
            TBA
          </p>

          <h4 class="mb-0 d-flex align-end">
            Workout Collection
            <v-spacer />
            <v-btn v-if="collection" text color="red" small @click="resetCollection" >
              <v-icon x-small class="me-2">fa-trash</v-icon>
              Select different collection
            </v-btn>
          </h4>
          <select-workout-collection-dialog v-model="request.collection_id" ref="workoutCollectionDialog" :collections="collections" @input="updateName" />
          <v-sheet v-if="!request.collection_id" class="dotted align-center clickable pa-6" @click="() => $refs.workoutCollectionDialog.open()">
            <v-icon>fa-play</v-icon>
            Choose a video collection
            <span style="color:red;">*</span>
          </v-sheet>
          <workout-collection-card v-if="collection" :item="collection" class="mb-4" />

          <v-text-field
            v-model="request.name" 
            label="Name of the challenge"
            />

          <DistanceTextArea
            v-if="collection"
            v-model="request.goal" 
            :mode="$helpers.UnitType.NUMBER"
            label="How many videos would you like to include?" 
            :hint="`We advise 2 per week. Maximum for this collection: ${collection.video_count}`"
            :rules="[
              v => v <= collection.video_count
            ]"
            />


          <v-alert v-if="collection && !isValid" outlined type="warning" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import DistanceTextArea from "@/components/DistanceTextArea";
import SelectWorkoutCollectionDialog from "@/components/eventmanager/SelectWorkoutCollectionDialog";
import assetsService from "@/services/assetsService";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
import WorkoutCollectionCard from '../../components/workouts/WorkoutCollectionCard.vue';
const tenant = tenants.current();

export default {
  name: "GenerateClaimChallengeDialog",
  components: {
    DistanceTextArea,
    SelectWorkoutCollectionDialog,
    WorkoutCollectionCard,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      siteData: siteData,
      collections: null,
      tenant: tenant,
      visible: false,
      request: { 
        name: null,
        goal: null,
        collection_id: null,
      },
    };
  },
  async mounted() {
    this.collections = (await assetsService.getWorkoutCollections()).data.data;
  },
  methods: {
    async open() {
      this.visible = true;
    },
    async resetCollection() {
      this.request.collection_id = null;
      this.request.goal = null;
      this.request.name = null;
    },
    updateName() {
      if (!this.request.name && this.collection) {
        this.request.name = this.collection.title;
        this.request.goal = this.collection.video_count;
      }
    }
  },
  computed: {
    collection() {
      if (!this.request.collection_id) {
        return null;
      }
      return this.collections.find(x => x.id == this.request.collection_id);
    },
    isValid() {
      return this.request.name && this.request.collection_id && this.request.goal;
    }
  },
};
</script>
<style lang="scss">
  .v-sheet.dotted {
    border: 2px dotted #ccc; border-radius: 4px; background: white;
  }
  
</style>

